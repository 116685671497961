export const environment = {
  APP_NAME: 'Chime',
  API_BASE_URL: '/api',
  production: true,

  CHAT_HOST   : 'chimesolutions.weborchestra.org/ws/6060',
  CHAT_KEY    : 'AQAAAAABAADv4t8MBihzjJOEhqaX9RpL',
  CHAT_SECURE : true
};

